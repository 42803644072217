import { GoogleCharts } from "google-charts/dist/googleCharts";

var myDepartment = null;
var currentDepartment = null;
var goto = null;
var toggler = null;
var switcher = null;
const departmentNameId = "#departmentName";
const departmentLinkId = "#departmentLink";

$(() => {

    initializeDepartmentSwitch();
    initializeDepartmentButton();
    initializeDepartmentPieChart();
    initializeDepartmentPage();
});

$(window).resize(() => {
    departmentBreak();
});

const initializeDepartmentPage = () => {
    
    if ($(departmentNameId)[0] === undefined)
        return;

    if ($(departmentLinkId)[0] === undefined)
        return

    const name = $(departmentNameId).val() as string;
    const link = $(departmentLinkId).val() as string;

    setCurrentDepartment(name, link);
}

const initializeDepartmentSwitch = () => {

    // Goto: Go to my department (button)
    // Toggler: Toggles department list (dropdown / button)
    // Switcher: Set or switch "my" department (button)

    goto = $("#my-department-button");
    toggler = $("#toggle-department-list-button");
    switcher = $("#switch-department-button");

    getMyDepartment();
    goto.click(gotoClicked);
    toggler.click(togglerClicked);
    switcher.click(switcherClicked);

    window["setCurrentDepartment"] = setCurrentDepartment;
}

const initializeDepartmentButton = () => {
    $('body').delegate('.my_department_link', 'click', function () {
        $("#my-department-button").trigger('click');
        return false;
    });

    if (window.location.pathname.indexOf('afdelinger') == 1) {

        // $('.my_department').html('<div class="my_department_link">' + $(".image-slider__heading").first().html() + '</div>');
        // $("#toggle-department-list-button").removeClass('"btn--icon-only"');
        departmentBreak();
    }
}

const initializeDepartmentPieChart = () => {

    const afdeling = $("#afdDepartment").data("afdeling");

    if (afdeling !== undefined && document.getElementById('piechart')) {
        // Load Google Charts
        GoogleCharts.load(
            drawChart, {
            packages: ['corechart', 'line'],
            'language': 'da',
            'mapsApiKey': ''
        });

        // Callback when API is ready
        var data;

        $.get("/api/ServiceFan/GetJson",
            function (result, textStatus, jqXHR) {
                for (var i = 0, l = result.length; i < l; i++) {

                    var obj = result[i];
                    if (obj.Id == afdeling) {
                        data = obj.Values;
                    }
                }
            });

        setTimeout(function () {
            GoogleCharts.load(drawChart(data));
        }, 500);
    }

    $(window).resize(function () {
        if (afdeling !== "undefined" && document.getElementById('piechart')) {
            drawChart(data);
        }
    });
}

const getMyDepartment = () => {
    var cookieName = getCookie("departmentName"),
        cookieUrl = getCookie("departmentUrl");

    if (cookieName !== null && cookieUrl !== null) {
        myDepartment = {
            name: cookieName,
            url: cookieUrl
        }

        toggler.addClass("btn--icon-only");
        goto.text("" + myDepartment.name);
        goto.show();
    }
}

const setCookie = (name, value) => {
    window.document.cookie = encodeURIComponent(name)
        + "=" + encodeURIComponent(value)
        + ";path=/;max-age=" + 2592e3; // Thirty days   
}

const getCookie = (name) => {
    // Borrowed from: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
    if (!name) { return null; }
    return decodeURIComponent(window.document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*"
        + encodeURIComponent(name).replace(/[\-\.\+\*]/g, "\\$&")
        + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
}

const gotoClicked = (e) => {
    window.location = myDepartment.url;
}

const togglerClicked = (e) => {
    $(".department__list").toggle();
}

const switcherClicked = (e) => {
    $(this).hide();
    $('.btn--dropdown').html(null);
    $('.btn--dropdown').css('padding-right', '0px');

    if (currentDepartment !== null) {
        setCookie("departmentName", currentDepartment.name);
        setCookie("departmentUrl", currentDepartment.url);
        getMyDepartment();
    }
}

const setCurrentDepartment = (name: string, url: string) => {
    currentDepartment = {
        name: fixSpecialChars(name),
        url: url
    }
    if (!myDepartment || currentDepartment.url !== myDepartment.url) {
        switcher.show();
    }
}

// TODO: this is a hack to fix æøå problems from EpiServer, better solution needed.
const fixSpecialChars = (str) => {
    return str.replace("&#229;", "å").replace("&#248;", "ø").replace("&#230;", "æ").replace("&#197;", "Å").replace("&#216;", "Ø").replace("&#198;", "Æ").replace("&#248;", "ø");
}

const departmentBreak = () => {
    if (window.location.pathname.indexOf('afdelinger') == 1) {
        if (window.innerWidth < 426) {
            $('.my_department').html($('.my_department').html().replace(',', '<br>'));
        } else {
            $('.my_department').html($('.my_department').html().replace('<br>', ','));
        }
    }
}

const drawChart = (data: any) => {
    if (data === undefined)
        return;

    let parsedData = [];
    let colors = [];

    $.each(data, function (index, value) {

        var entry = [value.Title, value.Percent, value.Color];
        colors.push(value.Color);
        parsedData.push(entry);
    });

    var table = new GoogleCharts.api.visualization.DataTable({
        cols: [
            { id: 'name', label: 'Name', type: 'string' },
            { id: 'value', label: 'Value', type: 'number' }
        ]
    });

    table.addColumn({
        type: 'string',
        role: 'style'
    });

    // Add data to the table
    table.addRows(parsedData);

    var pieoptions = {
        legend: 'none',
        chartArea: { top: 0, 'width': '100%', 'height': '100%' },
        tooltip: {
            text: 'none'
        },
        colors: colors
    };

    var piechart = new GoogleCharts.api.visualization.PieChart(document.getElementById('piechart'));
    piechart.draw(table, pieoptions);

    var k = 0;
    $('.labels').html(null);
    $(parsedData).each(function (i, v) {

        var label = '<div><div class="circlewrapper"><div class="labelcircle" style="background:' + v[2] + '"></div></div><div class="labeltext">' + v[0] + ' ' + '(' + v[1] + '%)' + '</div></div>';

        $('.labels').append(label);
        k++;
    });

    $("#chart").html(null);
}